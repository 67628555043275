import React from "react";
import { Form, Card, Row, Col, FormCheck } from "react-bootstrap";
import { ToggleAnswers, TotalExpressionsChanged, ToggleGroups } from "../../../js/actions/index";
import store from '../../../js/store/index';
import "./WorksheetConfigs.css";
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        showAnswers: state.showAnswers,
        totalExpressions: state.totalExpressions,
        group: state.group
    }
};

function WorksheetConfigs(props) {
    const { showAnswers, totalExpressions, group } = props;
    function toggleAnswersClicked(event) {
        store.dispatch(ToggleAnswers());
    }

    function totalExpressionsChange(event) {
        store.dispatch(TotalExpressionsChanged(event.target.value));
    }

    function toggleGroupsClicked(event) {
        store.dispatch(ToggleGroups());
    }


    return (
        <Card className="worksheet-configs mb-2">
            <Card.Header className="bg-primary text-light">Worksheet Configs</Card.Header>
            <Card.Body className="worksheet-configs-list">
                <Form>
                    <Form.Group as={Row} controlId="showAnswers" className="worksheet-config">
                        <Form.Label column xs={7}>Show answers</Form.Label>
                        <Col xs={5}>
                            <FormCheck checked={showAnswers} onChange={toggleAnswersClicked}></FormCheck>
                        </Col>
                    </Form.Group>
                </Form>
                <Form>
                    <Form.Group as={Row} controlId="groups" className="worksheet-config">
                        <Form.Label column xs={7}>Group expressions</Form.Label>
                        <Col xs={5}>
                            <FormCheck checked={group} onChange={toggleGroupsClicked}></FormCheck>
                        </Col>
                    </Form.Group>
                </Form>
                <Form>
                    <Form.Group as={Row} controlId="totalExpressions" className="worksheet-config">
                        <Form.Label column xs={7}>Total Expressions</Form.Label>
                        <Col xs={5}>
                            <Form.Control as="select" custom onChange={totalExpressionsChange} value={totalExpressions}>
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                                <option>60</option>
                                <option>80</option>
                                <option>100</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default connect(mapStateToProps)(WorksheetConfigs);