import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { RefreshExpressions } from '../../../js/actions/index';
import store from '../../../js/store/index';

const mapStateToProps = (state) => {
    return {
        expressions: state.expressions,
        startDateTime: state.startDateTime,
        shownAnswers: state.shownAnswers
    };
};

class CongratulationsModal extends Component {
    startAgainClicked = (event) => {
        store.dispatch(RefreshExpressions());
    }

    render() {
        const { expressions, shownAnswers, startDateTime } = this.props;
        const secondBetweenTwoDate = Math.abs((Date.now() - startDateTime) / 1000);
        const showAlert = expressions.length && !expressions.filter(e => e.answer !== e.correctAnswer).length;
        return (
            <Modal show={showAlert}>
                <Modal.Header>
                    <Modal.Title>Yay!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You completed {expressions.length} answers in {secondBetweenTwoDate} seconds {shownAnswers && <span>(with a little help)</span>}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.startAgainClicked}>Start again</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default connect(mapStateToProps)(CongratulationsModal);