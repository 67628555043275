import React from "react";
import { Card } from "react-bootstrap";
import ExpressionConfig from "../expressionconfig/ExpressionConfig";
import { connect } from "react-redux";
import "./ExpressionsConfig.css";

const mapStateToProps = (state) => {
    return {
      additionExpressionConfig: state.additionExpressionConfig,
      subtractionExpressionConfig: state.subtractionExpressionConfig,
      multiplicationExpressionConfig: state.multiplicationExpressionConfig,
      divisionExpressionConfig: state.divisionExpressionConfig
    }
  };

function ExpressionsConfig(props) {
    return (
        <Card className="expressions-config mb-2">
            <Card.Header className="bg-primary text-light">Expressions Config</Card.Header>
            <Card.Body className="expressions-config-list">
                <ExpressionConfig title="Addition" expressionConfig={props.additionExpressionConfig} />
                <ExpressionConfig title="Subtraction" expressionConfig={props.subtractionExpressionConfig} />
                <ExpressionConfig title="Multiplication" expressionConfig={props.multiplicationExpressionConfig} />
                <ExpressionConfig title="Division" expressionConfig={props.divisionExpressionConfig} />
            </Card.Body>
        </Card>
    );
}

export default connect(mapStateToProps)(ExpressionsConfig);