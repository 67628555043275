import React, { Component } from "react";
import { Form, Card, Row, Col } from "react-bootstrap";
import './Expression.css';
import * as Constants from "../../js/constants/index";
import { connect } from "react-redux";
import { FaPlus, FaMinus, FaDivide, FaTimes, FaEquals } from 'react-icons/fa';
import { ExpressionValueChanged } from '../../js/actions/index';
import store from '../../js/store/index';

const mapStateToProps = (state) => {
    return {
        showAnswers: state.showAnswers
    };
};

class Expression extends Component {
    onChange = (event) => {
        store.dispatch(
            ExpressionValueChanged(parseInt(event.target.getAttribute('expressionid')), event.target.value));
    }

    render() {
        const { expressionId, showAnswers, config, firstNumber, secondNumber, correctAnswer, answer } = this.props;
        let answerToShow = showAnswers ? correctAnswer : (answer === null ? "" : answer);
        let answerState = answerToShow === "" ? "" : (answerToShow === correctAnswer ? 'success' : 'danger');
        const userCorrectAnswer = correctAnswer === answer;
        return (
            <div className="col-md-6">
                <Card className={"expression " + config.type} bg={answerState ? answerState : "light"}>
                    <Card.Body>
                        <Row>
                            <Col xs={3}>{firstNumber}</Col>
                            <Col xs={1}>
                            {(function () {
                                    switch (config.type) {
                                        case Constants.ExpressionTypes.Addition:
                                            return <FaPlus />
                                        case Constants.ExpressionTypes.Subtraction:
                                            return <FaMinus />
                                        case Constants.ExpressionTypes.Multiplication:
                                            return <FaTimes />
                                        case Constants.ExpressionTypes.Division:
                                            return <FaDivide />
                                        default:
                                            return null;
                                    }
                                })()}
                            </Col>
                            <Col xs={3}>{secondNumber}</Col>
                            <Col xs={1}><FaEquals /></Col>
                            <Col xs={4}><Form.Control type="number" className="answer" value={answerToShow} expressionid={expressionId} readOnly={showAnswers || userCorrectAnswer} disabled={showAnswers || userCorrectAnswer} onChange={this.onChange} /></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default connect(mapStateToProps)(Expression);