import React from 'react';
import { push as Menu } from 'react-burger-menu';
import './MainMenu.css';
import ExpressionsConfig from '../configuration/expressionsconfig/ExpressionsConfig';
import WorksheetConfigs from '../configuration/worksheetconfigs/WorksheetConfigs';

function MainMenu(props) {
  return (
    <Menu pageWrapId={props.pageWrapId} outerContainerId={props.outerContainerId}>
      <WorksheetConfigs />
      <ExpressionsConfig />
    </Menu>);
}

export default MainMenu;