export var ActionTypes = {
    ExpressionConfigChanged: 'ExpressionConfigChanged',
    ToggleAnswers: 'ToggleAnswers',
    TotalExpressionsChanged: 'TotalExpressionsChanged',
    RefreshExpressions: 'RefreshExpressions',
    ExpressionValueChanged: 'ExpressionValueChanged',
    ToggleGroups: 'ToggleGroups'
};

export var ExpressionTypes = {
    Addition: 'Addition',
    Subtraction: 'Subtraction',
    Division: 'Division',
    Multiplication: 'Multiplication'
};