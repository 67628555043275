import React from "react";
import { Form, Card, Row, Col } from "react-bootstrap";
import { ExpressionConfigChanged } from "../../../js/actions/index";
import store from '../../../js/store/index';
import "./ExpressionConfig.css";

function ExpressionConfig(props) {
    const { expressionConfig, title } = props;
    function handleChange(event) {
        store.dispatch(
            ExpressionConfigChanged(
                {
                    ...expressionConfig,
                    [event.target.id]: event.target.value
                }));
    }

    function handleCheckChange(event) {
        store.dispatch(
            ExpressionConfigChanged(
                {
                    ...expressionConfig,
                    [event.target.id]: event.target.checked
                }));
    }

    return (
        <Card className="expression-config">
            <Card.Header className="bg-secondary text-light">
                {title} <input type="checkbox" className="float-right"
                    onChange={handleCheckChange}
                    checked={expressionConfig.enabled} id="enabled" />
            </Card.Header>
            <Card.Body className="expression-config-body">
                <Form>
                    {
                        expressionConfig.maxResultValue &&
                        <Form.Group as={Row} controlId="maxResultValue">
                            <Form.Label column xs={7}>Max result</Form.Label>
                            <Col xs={5}>
                                <Form.Control as="select" custom onChange={handleChange} value={expressionConfig.maxResultValue}>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>40</option>
                                    <option>50</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    }
                    {
                        expressionConfig.maxNumber &&
                        <Form.Group as={Row} controlId="maxNumber">
                            <Form.Label column xs={7}>Max number</Form.Label>
                            <Col xs={5}>
                                <Form.Control as="select" custom onChange={handleChange} value={expressionConfig.maxNumber}>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>40</option>
                                    <option>50</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    }
                    {
                        expressionConfig.maxFirstNumber &&
                        <Form.Group as={Row} controlId="maxFirstNumber">
                            <Form.Label column xs={7}>Max first number</Form.Label>
                            <Col xs={5}>
                                <Form.Control as="select" custom onChange={handleChange} value={expressionConfig.maxFirstNumber}>
                                    <option>2</option>
                                    <option>5</option>
                                    <option>10</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    }
                    {
                        expressionConfig.maxSecondNumber &&
                        <Form.Group as={Row} controlId="maxSecondNumber">
                            <Form.Label column xs={7}>Max second number</Form.Label>
                            <Col xs={5}>
                                <Form.Control as="select" custom onChange={handleChange} value={expressionConfig.maxSecondNumber}>
                                    <option>2</option>
                                    <option>5</option>
                                    <option>10</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    }
                    {
                        expressionConfig.maxDividendNumber &&
                        <Form.Group as={Row} controlId="maxDividendNumber">
                            <Form.Label column xs={7}>Max dividend</Form.Label>
                            <Col xs={5}>
                                <Form.Control as="select" custom onChange={handleChange} value={expressionConfig.maxDividendNumber}>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>40</option>
                                    <option>60</option>
                                    <option>80</option>
                                    <option>100</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    }
                </Form>
            </Card.Body>
        </Card>
    );
}

export default ExpressionConfig;