import React, { Component } from "react";
import { connect } from "react-redux";
import Expression from "../expression/Expression";
import { Alert, Card } from "react-bootstrap";
import * as Constants from "../../js/constants/index";

const mapStateToProps = (state) => {
    return {
        expressions: state.expressions,
        group: state.group
    };
};

class ExpressionList extends Component {
    getExpressionControl = expression => {
        return <Expression expressionId={expression.expressionId} key={expression.expressionId} config={expression.config} firstNumber={expression.firstNumber} secondNumber={expression.secondNumber} correctAnswer={expression.correctAnswer} answer={expression.answer} />
    }

    getExpressionsTypeControls = (name, expressions) => {
        return (<Card className="mb-2">
            <Card.Header className="bg-primary text-light">{name}</Card.Header>
            <Card.Body>
            <div className="expressionList row">
                {expressions}
                </div>
                </Card.Body>
            </Card>);
    }

    render() {
        const { expressions, group } = this.props;
        if (!expressions || !expressions.length) {
            return (
                <Alert variant="warning">
                    Please enable at least one expression
                </Alert>
            );
        } else {
            if (group) {
                var additionExpressions = expressions.filter(expression => expression.config.type === Constants.ExpressionTypes.Addition).map(expression => { return this.getExpressionControl(expression) });
                var subtractionExpressions = expressions.filter(expression => expression.config.type === Constants.ExpressionTypes.Subtraction).map(expression => { return this.getExpressionControl(expression) });
                var multiplyExpressions = expressions.filter(expression => expression.config.type === Constants.ExpressionTypes.Multiplication).map(expression => { return this.getExpressionControl(expression) });
                var divideExpressions = expressions.filter(expression => expression.config.type === Constants.ExpressionTypes.Division).map(expression => { return this.getExpressionControl(expression) });
                return <div>
                    {additionExpressions.length > 0 && this.getExpressionsTypeControls("Addition", additionExpressions)}
                    {subtractionExpressions.length > 0 && this.getExpressionsTypeControls("Subtraction", subtractionExpressions)}
                    {multiplyExpressions.length > 0 && this.getExpressionsTypeControls("Multiplication", multiplyExpressions)}
                    {divideExpressions.length > 0 && this.getExpressionsTypeControls("Division", divideExpressions)}
                </div>
            }
            else {
                return (
                    <div className="expressionList row">
                        {expressions.map(expression => { return this.getExpressionControl(expression);} )}
                    </div>
                );
            }
        }
    }
}

export default connect(mapStateToProps)(ExpressionList);
