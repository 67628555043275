import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainMenu from '../mainmenu/MainMenu';
import ExpressionList from '../expressionList/ExpressionList';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FaSyncAlt } from 'react-icons/fa';
import { RefreshExpressions } from '../../js/actions/index';
import store from '../../js/store/index';
import CongratulationsModal from '../modals/congratulationsmodal/CongratulationsModal';

const mapStateToProps = (state) => {
  return {
    additionExpressionConfig: state.additionExpressionConfig,
    subtractionExpressionConfig: state.subtractionExpressionConfig,
    multiplicationExpressionConfig: state.multiplicationExpressionConfig,
    divisionExpressionConfig: state.divisionExpressionConfig,
    shownAnswers: state.shownAnswers,
    sitename: state.sitename
  }
};

const App = (props) => {
  function refreshExpressionsClicked(event) {
    store.dispatch(RefreshExpressions());
  }

  const { additionExpressionConfig, subtractionExpressionConfig, multiplicationExpressionConfig, divisionExpressionConfig, shownAnswers, sitename } = props;
  let enabledExpressionNames = [];
  if (additionExpressionConfig.enabled) enabledExpressionNames.push("Addition");
  if (subtractionExpressionConfig.enabled) enabledExpressionNames.push("Subtraction");
  if (multiplicationExpressionConfig.enabled) enabledExpressionNames.push("Multiplication");
  if (divisionExpressionConfig.enabled) enabledExpressionNames.push("Division");
  const currentDate = new Date();
  return (
    <div className="App" id="outer-container">
      <CongratulationsModal />
      <MainMenu pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      <button className="refresh-expressions" onClick={refreshExpressionsClicked} aria-label="Refresh expressions"><FaSyncAlt /></button>
      <main id="page-wrap">
        <Container>
          <Row>
            <Col>
              <h1>{sitename}</h1>
              <h5>{enabledExpressionNames.join(' - ')} {shownAnswers && "(Shown answers)"} ({currentDate.toDateString()})</h5>
            </Col>
          </Row>
          <Row>
            <Col><ExpressionList /></Col>
          </Row>
        </Container>
        <footer className="container">
  <p>© {sitename} 2020-{currentDate.getFullYear()}</p>
        </footer>
      </main>
    </div>
  );
}

export default connect(mapStateToProps)(App);
