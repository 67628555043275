import * as Constants from "../constants/index";

export function ExpressionConfigChanged(config) {
    return {
        type: Constants.ActionTypes.ExpressionConfigChanged,
        payload: config
    };
}

export function ToggleAnswers() {
    return {
        type: Constants.ActionTypes.ToggleAnswers
    };
}

export function TotalExpressionsChanged(val) {
    return {
        type: Constants.ActionTypes.TotalExpressionsChanged,
        payload: val
    };
}

export function RefreshExpressions() {
    return {
        type: Constants.ActionTypes.RefreshExpressions
    };
}

export function ExpressionValueChanged(expressionId, value) {
    return {
        type: Constants.ActionTypes.ExpressionValueChanged,
        payload: {expressionId: expressionId, value: value}
    };
}

export function ToggleGroups() {
    return {
        type: Constants.ActionTypes.ToggleGroups
    };
}