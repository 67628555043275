import * as Constants from "../constants/index";
import { getRandomExpressions } from "../store/index";

function rootReducer(state, action) {
    switch (action.type) {
        case Constants.ActionTypes.ExpressionConfigChanged:
            switch (action.payload.type) {
                case Constants.ExpressionTypes.Addition:
                    {
                        let newState = Object.assign({},
                            state,
                            {
                                shownAnswers: state.showAnswers,
                                additionExpressionConfig: action.payload
                            });
                        resetState(newState);
                        return newState;
                    }
                case Constants.ExpressionTypes.Subtraction:
                    {
                        let newState = Object.assign({},
                            state,
                            {
                                shownAnswers: state.showAnswers,
                                subtractionExpressionConfig: action.payload
                            });
                        resetState(newState);
                        return newState;
                    }
                case Constants.ExpressionTypes.Multiplication:
                    {
                        let newState = Object.assign({},
                            state,
                            {
                                shownAnswers: state.showAnswers,
                                multiplicationExpressionConfig: action.payload
                            });
                        resetState(newState);
                        return newState;
                    }
                case Constants.ExpressionTypes.Division:
                    {
                        let newState = Object.assign({},
                            state,
                            {
                                shownAnswers: state.showAnswers,
                                divisionExpressionConfig: action.payload
                            });
                        resetState(newState);
                        return newState;
                    }
                default:
                    break;
            }
            break;

        case Constants.ActionTypes.ToggleAnswers:
            {
                let newState = Object.assign({}, state);
                newState.showAnswers = !newState.showAnswers;
                newState.shownAnswers = true;
                return newState;
            }

            case Constants.ActionTypes.ToggleGroups:
                {
                    let newState = Object.assign({}, state);
                    newState.group = !newState.group;
                    return newState;
                }

        case Constants.ActionTypes.TotalExpressionsChanged:
            {
                let newState = Object.assign({},
                    state,
                    {
                        shownAnswers: state.showAnswers,
                        totalExpressions: action.payload
                    });
                resetState(newState);
                return newState;
            }

        case Constants.ActionTypes.RefreshExpressions:
            {
                let newState = Object.assign({}, state,
                    {
                        shownAnswers: state.showAnswers
                    });
                resetState(newState);
                return newState;
            }

        case Constants.ActionTypes.ExpressionValueChanged:
            {
                let newState = Object.assign({}, state);
                let expression = newState.expressions.find(e => e.expressionId === action.payload.expressionId);
                if (expression) {
                    expression.answer = action.payload.value !== null && action.payload.value !== "" ? parseInt(action.payload.value) : null;
                }

                newState.expressions = [...newState.expressions];
                return newState;
            }

        default:
            return state;
    }
}

function resetState(state) {
    state.expressions = getRandomExpressions(state);
    state.startDateTime = Date.now();
    state.shownAnswers = false;
    state.showAnswers = false;
    window.scrollTo(0, 0);
}

export default rootReducer;