import { createStore } from "redux";
import rootReducer from "../reducers/index";
import * as Constants from "../../js/constants/index";

const additionExpressionConfig = {
    type: Constants.ExpressionTypes.Addition,
    enabled: true,
    maxResultValue: 20
};

const subtractionExpressionConfig = {
    type: Constants.ExpressionTypes.Subtraction,
    enabled: true,
    maxNumber: 20
};

const multiplicationExpressionConfig = {
    type: Constants.ExpressionTypes.Multiplication,
    enabled: true,
    maxFirstNumber: 5,
    maxSecondNumber: 10
};

const divisionExpressionConfig = {
    type: Constants.ExpressionTypes.Division,
    enabled: true,
    maxDividendNumber: 20
};

const initialState = {
    totalExpressions: 20,
    additionExpressionConfig: additionExpressionConfig,
    subtractionExpressionConfig: subtractionExpressionConfig,
    multiplicationExpressionConfig: multiplicationExpressionConfig,
    divisionExpressionConfig: divisionExpressionConfig,
    expressions: [],
    showAnswers: false,
    shownAnswers: false,
    startDateTime: Date.now(),
    group: true,
    sitename: "zipzapmaths.com"
};

initialState.expressions = getRandomExpressions(initialState);

export function getRandomExpressions(state) {
    let enabledExpressionConfigs = [];
    if (state.additionExpressionConfig.enabled) enabledExpressionConfigs.push(state.additionExpressionConfig);
    if (state.subtractionExpressionConfig.enabled) enabledExpressionConfigs.push(state.subtractionExpressionConfig);
    if (state.multiplicationExpressionConfig.enabled) enabledExpressionConfigs.push(state.multiplicationExpressionConfig);
    if (state.divisionExpressionConfig.enabled) enabledExpressionConfigs.push(state.divisionExpressionConfig);

    let expressions = [];
    if (enabledExpressionConfigs.length > 0) {
        let totalExpressionsRemaining = state.totalExpressions;
        let currentEnabledExpressionConfigIndex = 0;
        let expressionId = 1;
        while (totalExpressionsRemaining > 0) {
            let expression = getRandomExpression(enabledExpressionConfigs[currentEnabledExpressionConfigIndex], expressionId);
            if (expressions.find(element => element.config.type === expression.config.type
                && element.firstNumber === expression.firstNumber 
                && element.secondNumber === expression.secondNumber)) {
                expression = getRandomExpression(enabledExpressionConfigs[currentEnabledExpressionConfigIndex], expressionId);
            }
            expressions.push(expression);
            totalExpressionsRemaining--;
            currentEnabledExpressionConfigIndex++;
            expressionId++;
            if (currentEnabledExpressionConfigIndex >= enabledExpressionConfigs.length) {
                currentEnabledExpressionConfigIndex = 0;
            }
        }
    }

    // Shuffle the expressions
    for(let i = expressions.length-1; i > 0; i--){
        const j = Math.floor(Math.random() * i)
        const temp = expressions[i]
        expressions[i] = expressions[j]
        expressions[j] = temp
      }

    return expressions;
}

function getRandomExpression(expressionConfig, expressionId) {
    let expression = null;

    switch (expressionConfig.type) {
        case Constants.ExpressionTypes.Addition:
            expression = getRandomAdditionExpression(expressionConfig);
            break;

        case Constants.ExpressionTypes.Subtraction:
            expression = getRandomSubtractionExpression(expressionConfig);
            break;

        case Constants.ExpressionTypes.Multiplication:
            expression = getRandomMultiplicationExpression(expressionConfig);
            break;

        case Constants.ExpressionTypes.Division:
            expression = getRandomDivisionExpression(expressionConfig);
            break;

        default:
            break;
    }

    return {
        expressionId: expressionId,
        config: expressionConfig,
        firstNumber: expression.firstNumber,
        secondNumber: expression.secondNumber,
        correctAnswer: expression.answer,
        answer: null
    };
}

function getRandomAdditionExpression(expressionConfig) {
    const answer = getRandomNumber(expressionConfig.maxResultValue);
    const firstNumber = getRandomNumber(answer);
    const secondNumber = answer - firstNumber;
    return { firstNumber, secondNumber, answer};
}

function getRandomSubtractionExpression(expressionConfig) {
    const answer = getRandomNumber(expressionConfig.maxNumber);
    const firstNumber = getRandomNumber((expressionConfig.maxNumber - answer + 1)) + answer;
    const secondNumber = firstNumber - answer;
    return { firstNumber, secondNumber, answer};
}

function getRandomMultiplicationExpression(expressionConfig) {
    let firstNumber = getRandomNumber(expressionConfig.maxFirstNumber);
    if(firstNumber === 1) {
        firstNumber = getRandomNumber(expressionConfig.maxFirstNumber);
    }
    let secondNumber = getRandomNumber(expressionConfig.maxSecondNumber);
    if(secondNumber === 1) {
        secondNumber = getRandomNumber(expressionConfig.maxSecondNumber);
    }
    const answer = firstNumber * secondNumber;
    return { firstNumber, secondNumber, answer};
}

function getRandomDivisionExpression(expressionConfig) {
    const firstNumber = getRandomNumber(expressionConfig.maxDividendNumber);
    let answer = null;
    let secondNumber = null;
    if (firstNumber !== 0) {
        let divisors = [];
        for (let i = 1; i <= firstNumber; i++) {
            if (firstNumber % i === 0) {
                divisors.push(i);
            }
        }

        if (divisors.length > 2) {
            divisors = divisors.filter(item => item !== 1 && item !== firstNumber);
        }
        secondNumber = divisors[Math.floor(Math.random() * divisors.length)];
        answer = firstNumber / secondNumber;
    }
    else {
        secondNumber = Math.floor(Math.random() * expressionConfig.maxDividendNumber) + 1;
        answer = 0;
    }
    return { firstNumber, secondNumber, answer};
}

function getRandomNumber(maxNumber) {
    const number = Math.floor(Math.random() * maxNumber);
    if (number === 0) {
        return Math.floor(Math.random() * maxNumber);
    }

    return number;
}


const store = createStore(rootReducer, initialState);
export default store;